import { DataGrid } from 'devextreme-react';
import {
    Column,
    ColumnChooser,
    Editing,
    Export,
    FilterRow,
    Form,
    Pager,
    Paging,
    Popup,
    Selection,
} from 'devextreme-react/data-grid';
import { GroupItem, Item, Tab, TabbedItem } from 'devextreme-react/form';
import CustomStore from 'devextreme/data/custom_store';
import { useEffect, useRef, useState } from 'react';
import AddressContact from '../addressContact';
import ColumnConfig from '../data/columns/ship_to.json';
import { network } from '../platform/network';

const ShipTo = () => {
    const dataGridRef = useRef(null);
    const [dataSource, setDataSource] = useState<any>(null);
    const [shouldLoadData, setShouldLoadData] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingRowId, setEditingRowId] = useState(null);

    const [initialData] = useState(
        new CustomStore({
            key: 'id',
            load: async () => {
                return {
                    data: [],
                    totalCount: 0,
                };
            },
            insert: (values) => network.post(`/api/ship-to`, values),
            update: (key, values) => network.patch(`/api/ship-to/${key}`, values),
            remove: (key) => network.delete(`/api/ship-to/${key}`),
        }),
    );

    const [data] = useState(
        new CustomStore({
            key: 'id',
            load: async (params) => {
                let paramsObject = {};

                const paramNames = [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary',
                ];

                paramNames.forEach((name) => {
                    if (name !== undefined) {
                        paramsObject = { ...params, name };
                    }
                });

                const response = await network.get(`/api/ship-to`, { params: paramsObject });

                return {
                    data: response.data,
                    totalCount: +response.headers['x-total-records'],
                };
            },
            insert: (values) => network.post(`/api/ship-to`, values),
            update: (key, values) => network.patch(`/api/ship-to/${key}`, values),
            remove: (key) => network.delete(`/api/ship-to/${key}`),
        }),
    );

    useEffect(() => {
        if (shouldLoadData) {
            setDataSource(data);
        } else {
            setDataSource(initialData);
        }
    }, [shouldLoadData, data, initialData]);

    const onExporting = async () => {
        // TODO: export to excel
        // const { data } = await network.get('https://run.mocky.io/v3/1586f697-359f-4e50-aea3-35a60336bbff');
        // window.open(data.file);
    };

    return (
        <DataGrid
            id="gridContainer"
            ref={dataGridRef}
            dataSource={dataSource}
            keyExpr="id"
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            filterRow={{ visible: true }}
            rowAlternationEnabled={true}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            onExporting={onExporting}
            onEditingStart={(e) => {
                setIsEditing(true);
                setEditingRowId(e.data.id);
            }}
            onInitNewRow={() => setIsEditing(false)}
            remoteOperations={true}
            customizeColumns={(columns) => {
                columns.forEach((column) => {
                    column.visibleIndex = column.visibleIndex ?? 0 + 2;
                });
            }}
            onEditorPreparing={(e) => {
                // disable code field on editing
                if (e.value !== undefined && e.dataField === 'code' && e.parentType === 'dataRow') {
                    e.editorOptions.disabled = true;
                }
            }}
            toolbar={{
                items: [
                    { location: 'after', name: 'addRowButton' },
                    { location: 'after', name: 'exportButton' },
                    { location: 'after', name: 'columnChooserButton' },
                    {
                        location: 'after',
                        widget: 'dxButton',
                        options: { icon: 'clear', onClick: () => setShouldLoadData(false) },
                    },
                    {
                        location: 'after',
                        widget: 'dxButton',
                        options: {
                            icon: 'filter',
                            onClick: () => setShouldLoadData(true),
                        },
                    },
                ],
            }}
        >
            {/* toolbar - export excel */}
            <Export
                enabled={true}
                allowExportSelectedData={false}
            />

            {/* toolbar - column chooser */}
            <ColumnChooser enabled={true} />

            {/* column search */}
            <FilterRow visible={true} />

            {/* checkbox */}
            <Selection
                mode="multiple"
                showCheckBoxesMode="always"
            />

            {/* CRUD operations */}
            <Editing
                mode="popup"
                allowAdding={true}
                allowDeleting={false}
                allowUpdating={true}
                useIcons={true}
            >
                <Popup
                    title="Ship-To"
                    showTitle={true}
                    width={'80%'}
                    height={'80%'}
                />

                <Form>
                    <GroupItem>
                        <TabbedItem>
                            <Tab title="General">
                                <GroupItem
                                    itemType="group"
                                    colCount={3}
                                    items={[
                                        {
                                            dataField: 'code',
                                            label: { text: 'Ship-To' },
                                            isRequired: true,
                                        },
                                        {
                                            dataField: 'name',
                                            label: { text: 'Company' },
                                            isRequired: true,
                                        },
                                        {
                                            dataField: 'status',
                                            label: { text: 'Status' },
                                            isRequired: true,
                                            editorType: 'dxSelectBox',
                                            editorOptions: {
                                                dataSource: [
                                                    { name: 'Active', value: 'Active' },
                                                    { name: 'Inactive', value: 'Inactive' },
                                                ],
                                                displayExpr: 'name',
                                                valueExpr: 'value',
                                                searchEnabled: true,
                                                onInitialized: (e: any) => {
                                                    // const v = e.component.option('value');
                                                    // if (!v) {
                                                    //     e.component.option('value', 'Active');
                                                    // }
                                                },
                                            },
                                        },
                                    ]}
                                />

                                {isEditing && editingRowId && (
                                    <Item>
                                        <AddressContact
                                            moduleName="ship-to"
                                            refId={editingRowId}
                                        />
                                    </Item>
                                )}
                            </Tab>
                            <Tab title="Ordering Module">
                                <GroupItem
                                    itemType="group"
                                    colCount={2}
                                    items={[
                                        {
                                            dataField: 'outletCode',
                                            label: { text: 'Outlet Code' },
                                        },
                                        {
                                            dataField: 'accountCode',
                                            label: { text: 'Account Code' },
                                        },
                                    ]}
                                />

                                {isEditing && editingRowId && (
                                    <Item>
                                        <AddressContact
                                            moduleName="ship-to"
                                            refId={editingRowId}
                                        />
                                    </Item>
                                )}
                            </Tab>
                        </TabbedItem>
                    </GroupItem>
                </Form>
            </Editing>

            {/* columns */}
            {ColumnConfig.map((column: any) => (
                <Column
                    dataField={column.dataField}
                    caption={column.name}
                    visible={column.visible}
                />
            ))}

            {/* set action button column to first in order */}
            <Column
                type="buttons"
                visibleIndex={0}
            />

            {/* pagination */}
            <Paging
                defaultPageSize={10}
                defaultPageIndex={0}
            />

            <Pager
                visible={true}
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 30, 50, 100]}
                showNavigationButtons={true}
            />
        </DataGrid>
    );
};

export default ShipTo;
