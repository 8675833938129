import { useContext } from 'react';
import { AuthContext } from '../auth/AuthContext';
import { useLocalStorage } from './useLocalStorage';

// NOTE: optimally move this into a separate file
export interface User {
    accessToken: string;
}

export const useUser = () => {
    const { user, setUser, setIsLogin } = useContext(AuthContext);
    const { setItem, removeItem } = useLocalStorage();

    const addUser = (user: User) => {
        setIsLogin(true);
        setUser(user);
        setItem('user', JSON.stringify(user));
    };

    const removeUser = () => {
        setIsLogin(false);
        setUser(undefined);
        removeItem('user');
    };

    return { user, addUser, removeUser, setUser };
};
