import { DataGrid } from 'devextreme-react';
import {
    Column,
    ColumnChooser,
    Editing,
    Export,
    FilterRow,
    Form,
    Pager,
    Paging,
    Popup,
    Selection,
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import CustomStore from 'devextreme/data/custom_store';
import { useRef, useState } from 'react';
import ColumnConfig from '../data/columns/address_contact.json';
import { network } from '../platform/network';

interface ViewModel {
    moduleName: string;
    refId: number;
}

const AddressContact = ({ moduleName, refId }: ViewModel) => {
    const dataGridRef = useRef(null);

    const [data] = useState(
        new CustomStore({
            key: 'id',
            load: async (params) => {
                let paramsObject = {};

                const paramNames = [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary',
                ];

                paramNames.forEach((name) => {
                    if (name !== undefined) {
                        paramsObject = { ...params, name };
                    }
                });

                if (moduleName) {
                    paramsObject = { ...paramsObject, moduleName };
                }

                if (refId) {
                    paramsObject = { ...paramsObject, refId };
                }

                const response = await network.get(`/api/address-contact`, { params: paramsObject });

                return {
                    data: response.data,
                    totalCount: +response.headers['x-total-records'],
                };
            },
            insert: (values) => {
                let data = { ...values, refTable: moduleName, refId };
                return network.post(`/api/address-contact`, data);
            },
            update: (key, values) => network.patch(`/api/address-contact/${key}`, values),
            remove: (key) => network.delete(`/api/address-contact/${key}`),
        }),
    );

    const onExporting = async () => {
        // TODO: export to excel
        // const { data } = await network.get('https://run.mocky.io/v3/1586f697-359f-4e50-aea3-35a60336bbff');
        // window.open(data.file);
    };

    return (
        <DataGrid
            id="gridContainer"
            ref={dataGridRef}
            dataSource={data}
            keyExpr="id"
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            filterRow={{ visible: true }}
            rowAlternationEnabled={true}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            onExporting={onExporting}
            remoteOperations={true}
            customizeColumns={(columns) => {
                columns.forEach((column) => {
                    column.visibleIndex = column.visibleIndex ?? 0 + 2;
                });
            }}
            onEditorPreparing={(e) => {
                // disable code field on editing
                if (e.value !== undefined && e.dataField === 'code' && e.parentType === 'dataRow') {
                    e.editorOptions.disabled = true;
                }
            }}
            toolbar={{
                items: [
                    { location: 'after', name: 'addRowButton' },
                    { location: 'after', name: 'exportButton' },
                    { location: 'after', name: 'columnChooserButton' },
                    // {
                    //     location: 'after',
                    //     widget: 'dxButton',
                    //     options: { icon: 'clear', onClick: () => setShouldLoadData(false) },
                    // },
                    // {
                    //     location: 'after',
                    //     widget: 'dxButton',
                    //     options: {
                    //         icon: 'filter',
                    //         onClick: () => setShouldLoadData(true),
                    //     },
                    // },
                ],
            }}
        >
            {/* toolbar - export excel */}
            <Export
                enabled={true}
                allowExportSelectedData={false}
            />

            {/* toolbar - column chooser */}
            <ColumnChooser enabled={true} />

            {/* column search */}
            <FilterRow visible={true} />

            {/* checkbox */}
            <Selection
                mode="multiple"
                showCheckBoxesMode="always"
            />

            {/* CRUD operations */}
            <Editing
                mode="popup"
                allowAdding={true}
                allowDeleting={false}
                allowUpdating={true}
                useIcons={true}
            >
                <Popup
                    title="Address"
                    showTitle={true}
                    width={'80%'}
                    height={'80%'}
                />

                <Form>
                    <Item
                        itemType="tabbed"
                        colCount={2}
                        colSpan={2}
                        tabs={[
                            {
                                title: 'General',
                                items: [
                                    {
                                        itemType: 'group',
                                        colCount: 2,
                                        items: [
                                            {
                                                dataField: 'status',
                                                label: { text: 'Status' },
                                                editorType: 'dxSelectBox',
                                                isRequired: true,
                                                editorOptions: {
                                                    dataSource: [
                                                        { name: 'Active', value: 'Active' },
                                                        { name: 'Inactive', value: 'Inactive' },
                                                    ],
                                                    displayExpr: 'name',
                                                    valueExpr: 'value',
                                                    searchEnabled: true,
                                                    onInitialized: (e: any) => {
                                                        // const v = e.component.option('value');
                                                        // if (!v) {
                                                        //     e.component.option('value', 'Active');
                                                        // }
                                                    },
                                                },
                                            },
                                            {
                                                dataField: 'isDefault',
                                                label: { text: 'Default' },
                                                editorType: 'dxCheckBox',
                                                editorOptions: {
                                                    onInitialized: (e: any) => {
                                                        // const v = e.component.option('value');
                                                        // if (!v) {
                                                        //     e.component.option('value', true);
                                                        // }
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        itemType: 'group',
                                        caption: 'Address',
                                        colCount: 2,
                                        items: [
                                            {
                                                dataField: 'address1',
                                                label: { text: 'Address 1' },
                                            },
                                            {
                                                dataField: 'address2',
                                                label: { text: 'Address 2' },
                                            },
                                            {
                                                dataField: 'city',
                                                label: { text: 'City' },
                                            },
                                            {
                                                dataField: 'state',
                                                label: { text: 'State' },
                                            },
                                            {
                                                dataField: 'zip',
                                                label: { text: 'Zip' },
                                            },
                                            {
                                                dataField: 'country',
                                                label: { text: 'Country' },
                                            },
                                        ],
                                    },
                                    {
                                        itemType: 'group',
                                        caption: 'Contact Person',
                                        colCount: 2,
                                        items: [
                                            {
                                                dataField: 'primaryName',
                                                label: { text: 'Primary Contact Person' },
                                            },
                                            {
                                                dataField: 'secondaryName',
                                                label: { text: 'Secondary Contact Person' },
                                            },
                                            {
                                                dataField: 'primaryEmail',
                                                label: { text: 'Email 1' },
                                            },
                                            {
                                                dataField: 'secondaryEmail',
                                                label: { text: 'Email 2' },
                                            },
                                            {
                                                dataField: 'primaryOfficeTel',
                                                label: { text: 'Office TEL 1' },
                                            },
                                            {
                                                dataField: 'secondaryOfficeTel',
                                                label: { text: 'Office TEL 2' },
                                            },
                                            {
                                                dataField: 'primaryExt',
                                                label: { text: 'Ext 1' },
                                            },
                                            {
                                                dataField: 'secondaryExt',
                                                label: { text: 'Ext 2' },
                                            },
                                            {
                                                dataField: 'primaryMobile',
                                                label: { text: 'Mobile 1' },
                                            },
                                            {
                                                dataField: 'secondaryMobile',
                                                label: { text: 'Mobile 2' },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ]}
                    ></Item>
                </Form>
            </Editing>

            {/* columns */}
            {ColumnConfig.map((column: any) => (
                <Column
                    dataField={column.dataField}
                    caption={column.name}
                    visible={column.visible}
                />
            ))}

            {/* set action button column to first in order */}
            <Column
                type="buttons"
                visibleIndex={0}
            />

            {/* pagination */}
            <Paging
                defaultPageSize={10}
                defaultPageIndex={0}
            />

            <Pager
                visible={true}
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 30, 50, 100]}
                showNavigationButtons={true}
            />
        </DataGrid>
    );
};

export default AddressContact;
