import axios from 'axios';

export namespace Network {
    export const BASE_URL = process.env.API_URL || 'https://wd07oifx7g.execute-api.ap-southeast-1.amazonaws.com';
}

const instance = axios.create({
    baseURL: Network.BASE_URL,
});

instance.interceptors.request.use((config) => {
    const user = localStorage.getItem('user');

    if (user) {
        config.headers['Authorization'] = `Bearer ${JSON.parse(user).accessToken}` ?? '';
    }

    return config;
});

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        // TODO: 401 unauthorized error handling
        return Promise.reject(error);
    },
);

export { instance as network };
