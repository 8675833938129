import { DataGrid, DropDownBox } from 'devextreme-react';
import {
    Column,
    ColumnChooser,
    Editing,
    Export,
    FilterRow,
    Form,
    Pager,
    Paging,
    Popup,
    Selection,
} from 'devextreme-react/data-grid';
import { DropDownBox as DropDownBoxType } from 'devextreme-react/drop-down-box';
import { GroupItem, Item, SimpleItem, Tab, TabbedItem } from 'devextreme-react/form';
import CustomStore from 'devextreme/data/custom_store';
import { useCallback, useEffect, useRef, useState } from 'react';
import ColumnConfig from '../data/columns/location.json';
import { network } from '../platform/network';

const Location = () => {
    const dataGridRef = useRef(null);
    const [dataSource, setDataSource] = useState<any>(null);
    const [shouldLoadData, setShouldLoadData] = useState(false);
    let [putawayDataGridBoxValue, setPutawayDataGridBoxValue] = useState<any[]>([]);
    const putawayDropDownRef = useRef<DropDownBoxType>(null);
    let [pickingDataGridBoxValue, setPickingDataGridBoxValue] = useState<any[]>([]);
    const pickingDropDownRef = useRef<DropDownBoxType>(null);
    let [replenishDataGridBoxValue, setReplenishDataGridBoxValue] = useState<any[]>([]);
    const replenishDropDownRef = useRef<DropDownBoxType>(null);
    let [countDataGridBoxValue, setCountDataGridBoxValue] = useState<any[]>([]);
    const countDropDownRef = useRef<DropDownBoxType>(null);
    const formRef = useRef<any>(null);
    let putawayEnabled = true;
    let pickingEnabled = false;
    const formData = useRef<any>({});

    const pickingZoneStore = new CustomStore({
        load: async (params) => {
            let paramsObject = {};

            const paramNames = [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary',
            ];

            paramNames.forEach((name) => {
                if (name !== undefined) {
                    paramsObject = { ...params, name };
                }
            });

            const response = await network.get(`/api/picking-zone`, { params: paramsObject });

            return {
                data: response.data,
                totalCount: +response.headers['x-total-records'],
            };
        },
    });

    const putawayZoneStore = new CustomStore({
        load: async (params) => {
            let paramsObject = {};

            const paramNames = [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary',
            ];

            paramNames.forEach((name) => {
                if (name !== undefined) {
                    paramsObject = { ...params, name };
                }
            });

            const response = await network.get(`/api/putaway-zone`, { params: paramsObject });

            return {
                data: response.data,
                totalCount: +response.headers['x-total-records'],
            };
        },
    });

    const replenishZoneStore = new CustomStore({
        load: async (params) => {
            let paramsObject = {};

            const paramNames = [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary',
            ];

            paramNames.forEach((name) => {
                if (name !== undefined) {
                    paramsObject = { ...params, name };
                }
            });

            const response = await network.get(`/api/replenishment-zone`, { params: paramsObject });

            return {
                data: response.data,
                totalCount: +response.headers['x-total-records'],
            };
        },
    });

    const countZoneStore = new CustomStore({
        load: async (params) => {
            let paramsObject = {};

            const paramNames = [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary',
            ];

            paramNames.forEach((name) => {
                if (name !== undefined) {
                    paramsObject = { ...params, name };
                }
            });

            const response = await network.get(`/api/count-zone`, { params: paramsObject });

            return {
                data: response.data,
                totalCount: +response.headers['x-total-records'],
            };
        },
    });

    const [data] = useState(
        new CustomStore({
            key: 'id',
            load: async (params) => {
                let paramsObject = {};

                const paramNames = [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary',
                ];

                paramNames.forEach((name) => {
                    if (name !== undefined) {
                        paramsObject = { ...params, name };
                    }
                });

                const response = await network.get(`/api/warehouse-location`, { params: paramsObject });

                return {
                    data: response.data,
                    totalCount: +response.headers['x-total-records'],
                };
            },
            insert: (values) => {
                const data = { ...values, ...formData.current };
                return network.post(`/api/warehouse-location`, data);
            },
            update: (key, values) => {
                const data = { ...values, ...formData.current };
                return network.patch(`/api/warehouse-location/${key}`, data);
            },
            remove: (key) => network.delete(`/api/warehouse-location/${key}`),
        }),
    );

    useEffect(() => {
        if (shouldLoadData) {
            setDataSource(data);
        } else {
            setDataSource(null);
        }
    }, [shouldLoadData, data]);

    const onExporting = async () => {
        // TODO: export to excel
        // const { data } = await network.get('https://run.mocky.io/v3/1586f697-359f-4e50-aea3-35a60336bbff');
        // window.open(data.file);
    };

    const putawayDataGridOnSelectionChanged = useCallback(
        (e: any) => {
            putawayDataGridBoxValue[0] = e.selectedRowsData[0];
            setPutawayDataGridBoxValue(putawayDataGridBoxValue);
            putawayDropDownRef?.current?.instance._refresh();
            putawayDropDownRef?.current?.instance.close();
        },
        [putawayDataGridBoxValue],
    );

    const putawayDataGridRender = () => (
        <DataGrid
            keyExpr="code"
            dataSource={putawayZoneStore}
            columns={[
                {
                    dataField: 'code',
                    caption: 'Storage Zone',
                },
                'description',
            ]}
            showBorders={true}
            selectedRowKeys={putawayDataGridBoxValue}
            onSelectionChanged={putawayDataGridOnSelectionChanged}
            filterRow={{ visible: false }}
        >
            <Selection mode="single" />
            {/* <Scrolling mode="virtual" /> */}
            <Paging
                enabled={true}
                pageSize={10}
            />
        </DataGrid>
    );

    const pickingDataGridOnSelectionChanged = useCallback(
        (e: any) => {
            pickingDataGridBoxValue[0] = e.selectedRowsData[0];
            setPickingDataGridBoxValue(pickingDataGridBoxValue);
            pickingDropDownRef?.current?.instance._refresh();
            pickingDropDownRef?.current?.instance.close();
        },
        [pickingDataGridBoxValue],
    );

    const pickingDataGridRender = () => (
        <DataGrid
            keyExpr="code"
            dataSource={pickingZoneStore}
            columns={[
                {
                    dataField: 'code',
                    caption: 'Picking Zone',
                },
                'description',
            ]}
            showBorders={true}
            selectedRowKeys={pickingDataGridBoxValue}
            onSelectionChanged={pickingDataGridOnSelectionChanged}
            filterRow={{ visible: false }}
        >
            <Selection mode="single" />
            {/* <Scrolling mode="virtual" /> */}
            <Paging
                enabled={true}
                pageSize={10}
            />
        </DataGrid>
    );

    const replenishDataGridOnSelectionChanged = useCallback(
        (e: any) => {
            replenishDataGridBoxValue[0] = e.selectedRowsData[0];
            setReplenishDataGridBoxValue(replenishDataGridBoxValue);
            replenishDropDownRef?.current?.instance._refresh();
            replenishDropDownRef?.current?.instance.close();
        },
        [replenishDataGridBoxValue],
    );

    const replenishDataGridRender = () => (
        <DataGrid
            keyExpr="code"
            dataSource={replenishZoneStore}
            columns={[
                {
                    dataField: 'code',
                    caption: 'Replenish Zone',
                },
                'description',
            ]}
            showBorders={true}
            selectedRowKeys={replenishDataGridBoxValue}
            onSelectionChanged={replenishDataGridOnSelectionChanged}
            filterRow={{ visible: false }}
        >
            <Selection mode="single" />
            {/* <Scrolling mode="virtual" /> */}
            <Paging
                enabled={true}
                pageSize={10}
            />
        </DataGrid>
    );

    const countDataGridOnSelectionChanged = useCallback(
        (e: any) => {
            countDataGridBoxValue[0] = e.selectedRowsData[0];
            setCountDataGridBoxValue(countDataGridBoxValue);
            countDropDownRef?.current?.instance._refresh();
            countDropDownRef?.current?.instance.close();
        },
        [countDataGridBoxValue],
    );

    const countDataGridRender = () => (
        <DataGrid
            keyExpr="code"
            dataSource={countZoneStore}
            columns={[
                {
                    dataField: 'code',
                    caption: 'Count Zone',
                },
                'description',
            ]}
            showBorders={true}
            selectedRowKeys={countDataGridBoxValue}
            onSelectionChanged={countDataGridOnSelectionChanged}
            filterRow={{ visible: false }}
        >
            <Selection mode="single" />
            {/* <Scrolling mode="virtual" /> */}
            <Paging
                enabled={true}
                pageSize={10}
            />
        </DataGrid>
    );

    return (
        <DataGrid
            id="gridContainer"
            ref={dataGridRef}
            dataSource={dataSource}
            keyExpr="id"
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            filterRow={{ visible: true }}
            rowAlternationEnabled={true}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            onExporting={onExporting}
            remoteOperations={true}
            // onSaved={resetState}
            // onEditCanceled={resetState}
            customizeColumns={(columns) => {
                columns.forEach((column) => {
                    column.visibleIndex = column.visibleIndex ?? 0 + 2;
                });
            }}
            onEditorPreparing={(e) => {
                // disable code field on editing
                if (e.value !== undefined && e.dataField === 'code' && e.parentType === 'dataRow') {
                    e.editorOptions.disabled = true;
                }
            }}
            toolbar={{
                items: [
                    { location: 'after', name: 'addRowButton' },
                    { location: 'after', name: 'exportButton' },
                    { location: 'after', name: 'columnChooserButton' },
                    {
                        location: 'after',
                        widget: 'dxButton',
                        options: { icon: 'clear', onClick: () => setShouldLoadData(false) },
                    },
                    {
                        location: 'after',
                        widget: 'dxButton',
                        options: {
                            icon: 'filter',
                            onClick: () => setShouldLoadData(true),
                        },
                    },
                ],
            }}
        >
            {/* toolbar - export excel */}
            <Export
                enabled={true}
                allowExportSelectedData={false}
            />

            {/* toolbar - column chooser */}
            <ColumnChooser enabled={true} />

            {/* column search */}
            <FilterRow visible={true} />

            {/* checkbox */}
            <Selection
                mode="multiple"
                showCheckBoxesMode="always"
            />

            {/* CRUD operations */}
            <Editing
                mode="popup"
                allowAdding={true}
                allowDeleting={false}
                allowUpdating={true}
                useIcons={true}
            >
                <Popup
                    title="Location"
                    showTitle={true}
                    width={'80%'}
                    height={'80%'}
                    key={Math.random()}
                />

                <Form ref={formRef}>
                    <GroupItem>
                        <TabbedItem>
                            <Tab title="General">
                                <GroupItem
                                    itemType="group"
                                    colCount={2}
                                >
                                    <SimpleItem
                                        dataField="code"
                                        label={{ text: 'Location' }}
                                        isRequired={true}
                                    />

                                    <SimpleItem
                                        dataField="status"
                                        label={{ text: 'Status' }}
                                        isRequired={true}
                                        editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: [
                                                { name: 'Active', value: 'Active' },
                                                { name: 'Inactive', value: 'Inactive' },
                                            ],
                                            displayExpr: 'name',
                                            valueExpr: 'value',
                                            searchEnabled: true,
                                            onInitialized: (e: any) => {
                                                // const v = e.component.option('value');
                                                // if (!v) {
                                                //     e.component.option('value', 'Active');
                                                // }
                                            },
                                        }}
                                    />

                                    <SimpleItem
                                        dataField="locationType"
                                        label={{ text: 'Location Type' }}
                                        isRequired={true}
                                        editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: [
                                                { name: 'Selective Rack', value: 'Selective Rack' },
                                                { name: 'Double Deep Rack', value: 'Double Deep Rack' },
                                                { name: 'Triple Deep Rack', value: 'Triple Deep Rack' },
                                                { name: 'Shelves', value: 'Shelves' },
                                                { name: 'Gravity Rack', value: 'Gravity Rack' },
                                                { name: 'Drive In Rack', value: 'Drive In Rack' },
                                                { name: 'Drive Through Rack', value: 'Drive Through Rack' },
                                                { name: 'ASRS', value: 'ASRS' },
                                                { name: 'Floor', value: 'Floor' },
                                            ],
                                            displayExpr: 'name',
                                            valueExpr: 'value',
                                            searchEnabled: true,
                                            onInitialized: (e: any) => {
                                                // const v = e.component.option('value');
                                                // if (!v) {
                                                //     e.component.option('value', 'Active');
                                                // }
                                            },
                                        }}
                                    />

                                    <SimpleItem
                                        dataField="locationUsage"
                                        label={{ text: 'Location Usage' }}
                                        editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: [
                                                { name: 'Pallet Storage', value: 'Pallet Storage' },
                                                { name: 'Inbound Staging', value: 'Inbound Staging' },
                                                { name: 'Outbound Staging', value: 'Outbound Staging' },
                                                {
                                                    name: 'Inbound & Outbound Staging',
                                                    value: 'Inbound & Outbound Staging',
                                                },
                                                { name: 'Pick-Face Storage', value: 'Pick-Face Storage' },
                                                { name: 'Pick & Drop', value: 'Pick & Drop' },
                                                { name: 'Sorting', value: 'Sorting' },
                                                { name: 'Return', value: 'Return' },
                                                {
                                                    name: 'Package Stage Location',
                                                    value: 'Package Stage Location',
                                                },
                                            ],
                                            displayExpr: 'name',
                                            valueExpr: 'value',
                                            searchEnabled: true,
                                        }}
                                    />

                                    <SimpleItem
                                        dataField="putawayEnabled"
                                        label={{ text: 'Putaway Enabled' }}
                                        editorType="dxCheckBox"
                                        editorOptions={{
                                            value: putawayEnabled,
                                            onValueChanged: (e: any) => {
                                                putawayEnabled = e.component.option('value');
                                                putawayDropDownRef?.current?.instance.option(
                                                    'disabled',
                                                    !putawayEnabled,
                                                );
                                            },
                                            onInitialized: (e: any) => {
                                                const v = e.component.option('value');
                                                if (!v) {
                                                    e.component.option('value', putawayEnabled);
                                                }
                                            },
                                        }}
                                    />

                                    <SimpleItem
                                        dataField="pickingEnabled"
                                        label={{ text: 'Picking Enabled' }}
                                        editorType="dxCheckBox"
                                        editorOptions={{
                                            value: pickingEnabled,
                                            onValueChanged: (e: any) => {
                                                pickingEnabled = e.component.option('value');
                                                pickingDropDownRef?.current?.instance.option(
                                                    'disabled',
                                                    !pickingEnabled,
                                                );
                                            },
                                            onInitialized: (e: any) => {
                                                const v = e.component.option('value');
                                                if (!v) {
                                                    e.component.option('value', pickingEnabled);
                                                }
                                            },
                                        }}
                                    />

                                    <SimpleItem
                                        dataField="putawaySequence"
                                        label={{ text: 'Putaway Sequence' }}
                                    />

                                    <Item
                                        dataField="putawayZoneId"
                                        label={{ text: 'Putaway Zone' }}
                                    >
                                        <DropDownBox
                                            ref={putawayDropDownRef}
                                            displayExpr={'description'}
                                            value={putawayDataGridBoxValue}
                                            deferRendering={true}
                                            placeholder="Select a value..."
                                            showClearButton={true}
                                            contentRender={putawayDataGridRender}
                                            acceptCustomValue={true}
                                            opened={false}
                                            disabled={!putawayEnabled}
                                            bindingOptions={{
                                                disabled: 'putawayEnabled',
                                            }}
                                        />
                                    </Item>

                                    <SimpleItem
                                        dataField="pickingSequence"
                                        label={{ text: 'Picking Sequence' }}
                                    />

                                    <Item label={{ text: 'Picking Zone' }}>
                                        <DropDownBox
                                            ref={pickingDropDownRef}
                                            displayExpr={'description'}
                                            value={pickingDataGridBoxValue}
                                            deferRendering={true}
                                            placeholder="Select a value..."
                                            showClearButton={true}
                                            contentRender={pickingDataGridRender}
                                            acceptCustomValue={true}
                                            opened={false}
                                            disabled={!pickingEnabled}
                                            bindingOptions={{
                                                disabled: 'pickingEnabled',
                                            }}
                                        />
                                    </Item>

                                    <SimpleItem
                                        dataField="countSequence"
                                        label={{ text: 'Count Sequence' }}
                                    />

                                    <Item
                                        label={{ text: 'Count Zone' }}
                                        dataField="countZoneId"
                                    >
                                        <DropDownBox
                                            ref={countDropDownRef}
                                            displayExpr={'description'}
                                            value={countDataGridBoxValue}
                                            deferRendering={true}
                                            placeholder="Select a value..."
                                            showClearButton={true}
                                            contentRender={countDataGridRender}
                                            acceptCustomValue={true}
                                            opened={false}
                                        />
                                    </Item>

                                    <SimpleItem
                                        dataField="sortLPNForAllocationBy"
                                        label={{ text: 'Sort LPN for Allocation By' }}
                                        editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: [
                                                { name: 'First Putaway First Out', value: 'First Putaway First Out' },
                                                { name: 'Last Putaway First Out', value: 'Last Putaway First Out' },
                                            ],
                                            displayExpr: 'name',
                                            valueExpr: 'value',
                                            searchEnabled: true,
                                        }}
                                    />

                                    <Item
                                        label={{ text: 'Replenish Zone' }}
                                        dataField="replenishmentZoneId"
                                    >
                                        <DropDownBox
                                            ref={replenishDropDownRef}
                                            displayExpr={'description'}
                                            value={replenishDataGridBoxValue}
                                            deferRendering={true}
                                            placeholder="Select a value..."
                                            showClearButton={true}
                                            contentRender={replenishDataGridRender}
                                            acceptCustomValue={true}
                                            opened={false}
                                        />
                                    </Item>
                                </GroupItem>

                                <GroupItem
                                    itemType="group"
                                    colCount={3}
                                    caption="Location Profile & Capacity"
                                >
                                    <SimpleItem
                                        dataField="capacityCalculatedBy"
                                        label={{ text: 'Capacity Calculated By' }}
                                        editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: [
                                                { name: 'Master Unit Qty', value: 'Master Unit Qty' },
                                                { name: 'Case Qty', value: 'Case Qty' },
                                                { name: 'Pallet Qty', value: 'Pallet Qty' },
                                                { name: 'Master Unit Volume', value: 'Master Unit Volume' },
                                                { name: 'Case Volume', value: 'Case Volume' },
                                            ],
                                            displayExpr: 'name',
                                            valueExpr: 'value',
                                            searchEnabled: true,
                                        }}
                                    />

                                    <Item />
                                    <Item />

                                    <SimpleItem
                                        dataField="length"
                                        label={{ text: 'Length' }}
                                        isRequired={true}
                                    />

                                    <SimpleItem
                                        dataField="width"
                                        label={{ text: 'Width' }}
                                        isRequired={true}
                                    />

                                    <SimpleItem
                                        dataField="height"
                                        label={{ text: 'Height' }}
                                        isRequired={true}
                                    />

                                    <SimpleItem
                                        dataField="cube"
                                        label={{ text: 'Cube' }}
                                        isRequired={true}
                                    />

                                    <SimpleItem
                                        dataField="dimensionUOM"
                                        label={{ text: 'Dimension UOM' }}
                                        isRequired={true}
                                        editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: [
                                                { name: 'MM', value: 'MM' },
                                                { name: 'CM', value: 'CM' },
                                                { name: 'M', value: 'M' },
                                                { name: 'KM', value: 'KM' },
                                            ],
                                            displayExpr: 'name',
                                            valueExpr: 'value',
                                            searchEnabled: true,
                                        }}
                                    />

                                    <Item />

                                    <SimpleItem
                                        dataField="maxWeight"
                                        label={{ text: 'Max. Weight' }}
                                        isRequired={true}
                                    />

                                    <SimpleItem
                                        dataField="maxWeightUOM"
                                        label={{ text: 'Max. Weight UOM' }}
                                        isRequired={true}
                                        editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: [
                                                { name: 'G', value: 'G' },
                                                { name: 'KG', value: 'KG' },
                                                { name: 'MT', value: 'MT' },
                                            ],
                                            displayExpr: 'name',
                                            valueExpr: 'value',
                                            searchEnabled: true,
                                        }}
                                    />

                                    <Item />

                                    <SimpleItem
                                        dataField="palletFootPrint"
                                        label={{ text: 'Pallet FootPrint' }}
                                    />

                                    <SimpleItem
                                        dataField="palletStack"
                                        label={{ text: 'Pallet Stack' }}
                                    />

                                    <Item />

                                    <SimpleItem
                                        dataField="maxQtyMasterUnit"
                                        label={{ text: 'Max. Qty (Master Unit)' }}
                                    />

                                    <SimpleItem
                                        dataField="maxQtyCase"
                                        label={{ text: 'Max. Qty (Case)' }}
                                    />

                                    <Item />

                                    <SimpleItem
                                        dataField="allowedMixedItem"
                                        label={{ text: 'Allowed Mixed Item?' }}
                                        editorType="dxCheckBox"
                                        editorOptions={{
                                            value: true,
                                        }}
                                    />

                                    <SimpleItem
                                        dataField="allowedMixedItemProperties"
                                        label={{ text: 'Allowed Mixed Item Properties?' }}
                                        editorType="dxCheckBox"
                                        editorOptions={{
                                            value: true,
                                        }}
                                    />

                                    <Item />
                                </GroupItem>

                                <GroupItem
                                    itemType="group"
                                    colCount={3}
                                    caption="Positioning & Coordinates"
                                >
                                    <SimpleItem
                                        dataField="aisle"
                                        label={{ text: 'Aisle' }}
                                    />

                                    <SimpleItem
                                        dataField="bay"
                                        label={{ text: 'Bay' }}
                                    />

                                    <SimpleItem
                                        dataField="position"
                                        label={{ text: 'Position' }}
                                    />

                                    <SimpleItem
                                        dataField="level"
                                        label={{ text: 'Level' }}
                                    />

                                    <Item />
                                    <Item />

                                    <SimpleItem
                                        dataField="xCoordinate"
                                        label={{ text: 'X Coordinate' }}
                                    />

                                    <SimpleItem
                                        dataField="yCoordinate"
                                        label={{ text: 'Y Coordinate' }}
                                    />

                                    <SimpleItem
                                        dataField="zCoordinate"
                                        label={{ text: 'Z Coordinate' }}
                                    />
                                </GroupItem>
                            </Tab>
                        </TabbedItem>
                    </GroupItem>
                </Form>
            </Editing>

            {/* columns */}
            {ColumnConfig.map((column: any) => (
                <Column
                    dataField={column.dataField}
                    caption={column.name}
                    visible={column.visible}
                />
            ))}

            {/* set action button column to first in order */}
            <Column
                type="buttons"
                visibleIndex={0}
            />

            {/* pagination */}
            <Paging
                defaultPageSize={10}
                defaultPageIndex={0}
            />

            <Pager
                visible={true}
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 30, 50, 100]}
                showNavigationButtons={true}
            />
        </DataGrid>
    );
};

export default Location;
