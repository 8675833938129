import { useEffect, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { User, useUser } from './useUser';

export const useAuth = () => {
    // we can re export the user methods or object from this hook
    const { user, addUser, removeUser, setUser } = useUser();
    const { getItem } = useLocalStorage();
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        const user = getItem('user');
        if (user) {
            addUser(JSON.parse(user));
            setIsLogin(true);
        }
    }, [addUser, getItem]);

    const login = (user: User) => {
        setIsLogin(true);
        console.log('ahahahaa', isLogin);
        addUser(user);
    };

    const logout = () => {
        setIsLogin(false);
        removeUser();
    };

    return { user, login, logout, setUser, isLogin, setIsLogin };
};
