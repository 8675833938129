import { Alert, Button, Form, Grid, Input, theme, Typography } from 'antd';

import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { network } from '../platform/network';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Title } = Typography;

export default function AuthLogin() {
    const { token } = useToken();
    const screens = useBreakpoint();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const { login } = useAuth();

    const onFinish = async (values: any) => {
        console.log('Received values of form: ', values);

        setIsSubmitting(true);

        try {
            const response = await network.post(`/api/auth/login`, {
                email: values.email,
                password: values.password,
            });

            const { data } = response;

            login({
                accessToken: data.accessToken,
            });
        } catch (e: any) {
            setIsSubmitting(false);
            if (e.response.status === 400) {
                setError('Wrong email or password');
            }
        }

        setIsSubmitting(false);
    };

    const styles = {
        container: {
            margin: '0 auto',
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            width: '380px',
        },
        footer: {
            marginTop: token.marginLG,
            textAlign: 'center',
            width: '100%',
        },
        forgotPassword: {
            float: 'right',
        },
        header: {
            marginBottom: token.marginXL,
        },
        section: {
            alignItems: 'center',
            backgroundColor: token.colorBgContainer,
            display: 'flex',
            height: screens.sm ? '100vh' : 'auto',
            padding: screens.md ? `${token.sizeXXL}px 0px` : '0px',
        },
        text: {
            color: token.colorTextSecondary,
        },
        title: {
            fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
        },
    };

    return (
        <section style={styles.section}>
            <div style={styles.container}>
                <div style={styles.header}>
                    <Title style={styles.title}>Sign in</Title>
                </div>

                {error ? (
                    <Alert
                        message={error}
                        type="error"
                        showIcon
                        style={{ marginBottom: 16 }}
                    />
                ) : null}

                <Form
                    name="normal_login"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark="optional"
                    size="large"
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                required: true,
                                message: 'Please input your Email!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="Email"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    {/* <Form.Item>
                        <Form.Item
                            name="remember"
                            valuePropName="checked"
                            noStyle
                        >
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                    </Form.Item> */}
                    <Form.Item style={{ marginBottom: '0px' }}>
                        <Button
                            block={true}
                            type="primary"
                            htmlType="submit"
                            disabled={isSubmitting}
                        >
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </section>
    );
}
