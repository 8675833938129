import { HomeFilled, LineChartOutlined, MenuFoldOutlined, MenuOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Image, Layout, Menu, MenuProps, Tabs, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import React, { useRef, useState } from 'react';
import './App.css';
import AuthLogin from './auth';
import { AuthContext } from './auth/AuthContext';
import BillTo from './bill-to';
import Carrier from './carrier';
import CountZone from './count-zone';
import Customer from './customer';
import './dx.css';
import { useAuth } from './hooks/useAuth';
import Location from './location';
import Owner from './owner';
import PickingZone from './picking-zone';
import PutawayZone from './putaway-zone';
import ReplenishmentZone from './replenishment-zone';
import ShipTo from './ship-to';
import Supplier from './supplier';
import SystemCode from './system-code';
import Warehouse from './warehouse';

enum MenuItemName {
    Home = 'Home',
    Dashboard = 'Dashboard',
    Inbound = 'Inbound',
    InventoryManagement = 'Inventory Management',
    Setup = 'Setup',
    Master = 'Master',
    Owner = 'Owner',
    ExternalEntities = 'External Entities',
    ShipTo = 'Ship-To',
    BillTo = 'Bill-To',
    Supplier = 'Supplier',
    Customer = 'Customer',
    Carrier = 'Carrier',
    SystemSettings = 'System Settings',
    SystemCodes = 'System Codes',
    Warehouse = 'Warehouse',
    CountZone = 'Count Zone',
    PutawayZone = 'Putaway Zone',
    PickingZone = 'Picking Zone',
    ReplenishmentZone = 'Replenishment Zone',
    Location = 'Location',
    WarehouseItem = 'Warehouse',
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: MenuItemName,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const items: MenuProps['items'] = [
    getItem(MenuItemName.Home, 'Home', <HomeFilled />),
    getItem(MenuItemName.Dashboard, 'Dashboard', <LineChartOutlined />),

    // getItem('Inbound', 'Inbound', <LogoutOutlined />, [
    //     getItem('Purchase Order', 'Purchase Order'),
    //     getItem('Receipt', 'Receipt'),
    //     getItem('Receipt Inquiry', 'Receipt Inquiry'),
    // ]),

    // getItem('Inventory Management', 'Inventory Management', <MenuOutlined />, [
    //     getItem('Inventory Inquiry', 'Inventory Inquiry', <MenuOutlined />, [
    //         getItem('Inventory Balance Detail', 'Inventory Balance Detail', <MenuOutlined />),
    //         getItem('Item Summary', 'Item Summary', <MenuOutlined />),
    //     ]),

    //     getItem('Inventory Transaction', 'Inventory Transaction', <MenuOutlined />),
    //     getItem('Serial Transaction', 'Serial Transaction', <MenuOutlined />),
    //     getItem('Inventory Adjustment', 'Inventory Adjustment', <MenuOutlined />),

    //     getItem('Inventory Move', 'Inventory Move', <MenuOutlined />, [
    //         getItem('Move', 'Move', <MenuOutlined />),
    //         getItem('Move LPN', 'Move LPN', <MenuOutlined />),
    //     ]),

    //     getItem('Inventory Hold', 'Inventory Hold', <MenuOutlined />),
    //     getItem('System Lot Change', 'System Lot Change', <MenuOutlined />),

    //     getItem('Inventory Count', 'Inventory Count', <MenuOutlined />, [
    //         getItem('Cycle Count', 'Cycle Count', <MenuOutlined />),
    //         getItem('Stock Take', 'Stock Take', <MenuOutlined />),
    //     ]),

    //     getItem('Replenishment Task', 'Replenishment Task', <MenuOutlined />),
    // ]),

    getItem(MenuItemName.Setup, 'Setup', <MenuOutlined />, [
        getItem(MenuItemName.Master, 'Master', <MenuOutlined />, [
            getItem(MenuItemName.Owner, 'Owner', <MenuOutlined />),
            getItem(MenuItemName.ExternalEntities, 'External Entities', <MenuOutlined />, [
                getItem(MenuItemName.Supplier, 'Supplier', <MenuOutlined />),
                getItem(MenuItemName.ShipTo, 'Ship-To', <MenuOutlined />),
                getItem(MenuItemName.BillTo, 'Bill-To', <MenuOutlined />),
                getItem(MenuItemName.Customer, 'Customer', <MenuOutlined />),
                getItem(MenuItemName.Carrier, 'Carrier', <MenuOutlined />),
            ]),
            getItem(MenuItemName.Warehouse, 'Warehouse', <MenuOutlined />, [
                getItem(MenuItemName.WarehouseItem, 'Warehouse', <MenuOutlined />),
                getItem(MenuItemName.Location, 'Location', <MenuOutlined />),
                getItem(MenuItemName.CountZone, 'Count Zone', <MenuOutlined />),
                getItem(MenuItemName.PutawayZone, 'Putaway Zone', <MenuOutlined />),
                getItem(MenuItemName.PickingZone, 'Picking Zone', <MenuOutlined />),
                getItem(MenuItemName.ReplenishmentZone, 'Replenishment Zone', <MenuOutlined />),
            ]),
        ]),
    ]),

    getItem(MenuItemName.SystemSettings, 'System Settings', <MenuOutlined />, [
        getItem(MenuItemName.SystemCodes, 'System Codes', <MenuOutlined />),
    ]),
];

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface TabPane {
    label: React.ReactNode;
    children: React.ReactNode;
    key: string;
}

function App() {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const [collapsed, setCollapsed] = useState(false);
    const [tabs, setTabs] = useState<TabPane[]>([]);
    const [activeKey, setActiveKey] = useState('0');
    const newTabIndex = useRef(0);

    const tabPaneContents: Map<string, React.ReactNode> = useRef(new Map()).current;
    tabPaneContents.set(MenuItemName.Owner, <Owner />);
    tabPaneContents.set(MenuItemName.ShipTo, <ShipTo />);
    tabPaneContents.set(MenuItemName.BillTo, <BillTo />);
    tabPaneContents.set(MenuItemName.Supplier, <Supplier />);
    tabPaneContents.set(MenuItemName.Customer, <Customer />);
    tabPaneContents.set(MenuItemName.Carrier, <Carrier />);
    tabPaneContents.set(MenuItemName.SystemCodes, <SystemCode />);
    tabPaneContents.set(MenuItemName.CountZone, <CountZone />);
    tabPaneContents.set(MenuItemName.PutawayZone, <PutawayZone />);
    tabPaneContents.set(MenuItemName.PickingZone, <PickingZone />);
    tabPaneContents.set(MenuItemName.ReplenishmentZone, <ReplenishmentZone />);
    tabPaneContents.set(MenuItemName.Location, <Location />);
    tabPaneContents.set(MenuItemName.WarehouseItem, <Warehouse />);

    const onChange = (key: string) => {
        setActiveKey(key);
    };

    const addTab = (tabName: string) => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        setTabs([...tabs, { label: tabName, children: tabPaneContents.get(tabName), key: newActiveKey }]);
        setActiveKey(newActiveKey);
    };

    const removeTab = (targetKey: TargetKey) => {
        const targetIndex = tabs.findIndex((pane) => pane.key === targetKey);
        const newPanes = tabs.filter((pane) => pane.key !== targetKey);
        if (newPanes.length && targetKey === activeKey) {
            const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
            setActiveKey(key);
        }
        setTabs(newPanes);
    };

    const onEdit = (targetKey: TargetKey, action: 'add' | 'remove') => {
        if (action === 'remove') {
            removeTab(targetKey);
        }
    };

    const { user, setUser, isLogin, setIsLogin, logout } = useAuth();

    return (
        <AuthContext.Provider value={{ user, setUser, isLogin, setIsLogin }}>
            <AuthContext.Consumer>
                {(authCtx) => {
                    return authCtx.isLogin ? (
                        <Layout hasSider>
                            <Sider
                                style={{ overflow: 'auto', height: '100vh', position: 'fixed' }}
                                trigger={null}
                                collapsible
                                collapsed={collapsed}
                                width={300}
                            >
                                <div className="demo-logo-vertical" />
                                <Menu
                                    theme="dark"
                                    mode="inline"
                                    defaultSelectedKeys={['4']}
                                    items={items}
                                    onClick={(e) => addTab(e.key)}
                                />
                            </Sider>
                            <Layout style={{ marginLeft: collapsed ? 80 : 300 }}>
                                <Header
                                    style={{
                                        padding: 0,
                                        background: colorBgContainer,
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <div>
                                        <Button
                                            type="text"
                                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                            onClick={() => setCollapsed(!collapsed)}
                                            style={{
                                                fontSize: '16px',
                                                width: 64,
                                                height: 64,
                                            }}
                                        />
                                        <Image
                                            src="https://wms-dev.ac2wave.com/PEGASUS/static-resources/img/A23002.png"
                                            preview={false}
                                            height={40}
                                        />
                                    </div>
                                    <Button
                                        style={{ marginRight: 16 }}
                                        onClick={logout}
                                    >
                                        Logout
                                    </Button>
                                </Header>
                                <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                                    <div
                                        style={{
                                            padding: 24,
                                            textAlign: 'center',
                                            background: colorBgContainer,
                                            borderRadius: borderRadiusLG,
                                        }}
                                    >
                                        <Tabs
                                            hideAdd
                                            onChange={onChange}
                                            activeKey={activeKey}
                                            type="editable-card"
                                            onEdit={onEdit}
                                            items={tabs}
                                            tabBarStyle={{ flexWrap: 'wrap' }}
                                            moreIcon={null}
                                            popupClassName="tabs-popup"
                                        />
                                    </div>
                                </Content>
                                <Footer style={{ textAlign: 'center' }}>©{new Date().getFullYear()}</Footer>
                            </Layout>
                        </Layout>
                    ) : (
                        <AuthLogin />
                    );
                }}
            </AuthContext.Consumer>
        </AuthContext.Provider>
    );
}

export default App;
